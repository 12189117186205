<template>
  <div>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Date de signature : {{ ISOtoUSR(c.date_signature) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Durée : {{ c.duree_bail }} ans</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Loyer : {{ c.loyer_bail }} {{ c.unite_loyer_bail }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
</template>

<script>
import {format_functions} from "../../../mixins/UtilityMixin";
export default {
  name: "PromesseDeBail",
  props: ['c'],
  mixins: [format_functions],
}
</script>

<style scoped>

</style>